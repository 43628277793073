<app-dialog-title [displayTitle]="title" [loading]="loading()" />

<mat-dialog-content>
  <form [formGroup]="formGroup" id="class-form" (submit)="isValid() && save()">
    @if (series) {
      <div class="tw-mb-2 tw-flex tw-flex-row">
        <app-output label="Class name" class="tw-w-1/2 !tw-pl-2">{{ data.parentClass!.name }}</app-output>
        <app-output label="Term" class="tw-w-1/2 !tw-pl-2">{{ data.parentClass!.termName }}</app-output>
      </div>
    } @else {
      <mat-form-field class="tw-w-full">
        <mat-label>Term</mat-label>
        <mat-select formControlName="idtTerm">
          @for (term of terms$ | async; track term.idtTerm) {
            <mat-option [value]="term.idtTerm">{{ term.name }}</mat-option>
          }
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>
    }

    <div class="tw-flex tw-flex-row tw-gap-1">
      <mat-form-field class="tw-w-1/3">
        <mat-label>{{ series ? 'Series' : 'Class' }} Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      @if (series) {
        <app-output class="tw-w-1/3 !tw-pl-2" label="Type">
          {{ data.parentClass!.type.length > 2 ? (data.parentClass!.type | titlecase) : data.parentClass!.type }}
        </app-output>

        <app-output class="tw-w-1/3 !tw-pl-2" label="Return Basis">
          {{ data.parentClass!.returnBasis === ReturnBasisEnum.NAV ? 'NAV Return' : 'Market return' }}
        </app-output>
      } @else {
        <mat-form-field class="tw-w-1/3">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option [value]="ClassTypeEnum.LP">LP</mat-option>
            <mat-option [value]="ClassTypeEnum.OFFSHORE">Offshore</mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
        <mat-form-field class="tw-w-1/3">
          <mat-label>Return Basis</mat-label>
          <mat-select formControlName="returnBasis">
            <mat-option [value]="ReturnBasisEnum.NAV">NAV Return</mat-option>
            <mat-option [value]="ReturnBasisEnum.MARKET">Market Return</mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
      }
    </div>

    <div class="tw-flex tw-flex-row tw-gap-1">
      <mat-form-field class="tw-w-1/2">
        <mat-label>Status</mat-label>
        <mat-select formControlName="inactive">
          <mat-option [value]="false">Active</mat-option>
          <mat-option [value]="true">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="tw-w-1/2">
        <mat-label>Situation</mat-label>
        <mat-select formControlName="situation">
          <mat-option [value]="1">Open</mat-option>
          <mat-option [value]="2">Closed</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="tw-flex tw-flex-row tw-gap-1">
      @if (series) {
        <app-output label="Currency" class="tw-w-1/2 !tw-pl-2">{{ data.parentClass!.currency }}</app-output>
        <app-output label="Hot Issue Eligibility" class="tw-w-1/2 !tw-pl-2">{{ data.parentClass!.hotIssueEligibility | titlecase }}</app-output>
      } @else {
        <mat-form-field class="tw-w-1/2">
          <mat-label>Currency</mat-label>
          <input type="text" matInput [formControl]="currencyControl" [matAutocomplete]="currencyAuto" #currencyInput />
          <mat-autocomplete #currencyAuto="matAutocomplete" requireSelection [displayWith]="displayCurrency">
            @for (option of currencyOptions(); track option.id) {
              <mat-option [value]="option">{{ option.currency.name }}</mat-option>
            }
          </mat-autocomplete>
          <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field class="tw-w-1/2">
          <mat-label>Hot Issue Eligibility</mat-label>
          <mat-select formControlName="hotIssueEligibility">
            <mat-option [value]="ClassHotIssueEligibilityEnum.RESTRICTED">Restricted</mat-option>
            <mat-option [value]="ClassHotIssueEligibilityEnum.UNRESTRICTED">Unrestricted</mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
      }
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions class="tw-gap-1">
  <button mat-button matDialogClose>Cancel</button>
  @if (edition) {
    <button mat-button color="warn" (click)="deleteClass()">Delete</button>
  }
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" form="class-form">Save</button>
</div>
