import { ChangeDetectionStrategy, Component, computed, effect, EventEmitter, input, Input, Output, signal } from '@angular/core';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { DateTime } from 'luxon';
import { PortfolioAllocation } from '../../model/portfolio-allocation.model';
import { PortfolioService } from '../../services/portfolio.service';

/**
 * Component that represents a list of all system portfolios and its allocations.
 */
@Component({
  selector: 'app-portfolio-allocation',
  templateUrl: './portfolio-allocation.component.html',
  styleUrls: ['./portfolio-allocation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioAllocationComponent {
  constructor(private portfolioService: PortfolioService) {}

  /*
   * Firm totalization item.
   */
  total: PortfolioAllocation | undefined;

  /**
   * The selected portfolio.
   */
  @Input()
  selectedPortfolio?: PortfolioAllocation;

  /**
   * Emits the select event.
   */
  @Output()
  selectPortfolio = new EventEmitter<PortfolioAllocation | undefined>();

  /**
   * Emits when selected date is changed.
   */
  @Output()
  dateChanged = new EventEmitter<DateTime>();

  loading = signal(true);

  /**
   * Whether there are estimated balance values.
   */
  isEstimated = computed(() => {
    return this.portfolios().some((a) => a.estimated);
  });

  asOf = input.required<DateTime>();

  private asOfEffect = effect(
    () => {
      if (this.asOf()) {
        this.getAllocations();
      }
    },
    { allowSignalWrites: true },
  );

  portfolios = signal<PortfolioAllocation[]>([]);

  private getAllocations() {
    this.portfolioService
      .findAllocations(this.asOf())
      .pipe(trackLoading(this.loading))
      .subscribe((allocations) => {
        this.total = allocations[0];
        this.portfolios.set(allocations);
      });
  }

  /**
   * Select portfolio and emit an event to the parent component.
   * @param idtPortfolio portfolio id.
   */
  select(portfolio?: PortfolioAllocation): void {
    if (this.selectedPortfolio?.idtPortfolio === portfolio?.idtPortfolio) {
      this.selectPortfolio.emit(undefined);
    } else {
      this.selectPortfolio.emit(portfolio);
    }
  }
}
