import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { AccountIndexXtdTO, IndexedIndexTO, IndexXtdTO } from '../models/index.model';

/**
 * Service for index related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class IndexService {
  constructor(private http: HttpClient) {}

  /**
   * Get the xTDs for the index related to the provided account.
   *
   * @param idtAccount the account id
   * @param refDate referente date
   * @returns an observable that emits the index xtds when the server responds
   */
  getAccountIndexXtd(idtAccount: number, refDate: DateTime): Observable<AccountIndexXtdTO> {
    return this.http.get<AccountIndexXtdTO>(`${environment.apiUrl}/index`, {
      params: {
        idtAccount,
        refDate: refDate.toISODate(),
      },
    });
  }

  /**
   * Get index xTDs for the provided portfolio.
   *
   * @param idtPortfolio the portfolio id
   * @param refDate the reference date
   * @returns an observable that emits the index xtds when the server responds
   */
  getPortfolioIndexXtd(idtPortfolio: number, refDate: DateTime) {
    return this.http.get<IndexXtdTO>(`${environment.apiUrl}/index`, {
      params: {
        idtPortfolio,
        refDate: refDate.toISODate(),
      },
    });
  }

  /**
   * Search for indexes.
   *
   * @param searchTerm the term to search for
   * @returns an observable that emits the indexes found
   */
  searchIndex(searchTerm: string) {
    return this.http.get<IndexedIndexTO[]>(`${environment.apiUrl}/index/search`, { params: { searchTerm } });
  }
}
