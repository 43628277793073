<app-dialog-title displayTitle="Social Media" [loading]="saving() || loading()" />

<mat-dialog-content>
  <form (submit)="save()" id="social-form" [formGroup]="formGroup">
    <div class="tw-flex tw-flex-col">
      <div class="abs-dashed-box abs-margin-bottom tw-flex tw-flex-row tw-items-center tw-gap-1">
        <mat-icon fontIcon="mdi-linkedin" />
        <mat-form-field subscriptSizing="dynamic" class="tw-flex-1">
          <mat-label>Linkedin</mat-label>
          <input matInput formControlName="linkedin" maxlength="280" />
        </mat-form-field>
      </div>

      <div class="abs-dashed-box abs-margin-bottom tw-flex tw-flex-row tw-items-center tw-gap-1">
        <mat-icon fontIcon="mdi-web" />
        <mat-form-field subscriptSizing="dynamic" class="tw-flex-1">
          <mat-label>Website</mat-label>
          <input matInput formControlName="website" maxlength="280" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="social-form">Save</button>
</div>
