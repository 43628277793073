<div class="tab-tools" fxLayoutAlign="end center">
  @if (!inactive) {
    <button mat-icon-button color="primary" *hasPermission="'CREATE_TRANSACTION'" matTooltip="Add balance" (click)="createBalance()">
      <mat-icon fontIcon="mdi-plus-circle" />
    </button>
  }

  <button mat-icon-button color="primary" (click)="export()" matTooltip="Export to Excel">
    <mat-icon fontIcon="mdi-file-download" />
  </button>
</div>

@if (dataSource()) {
  <div fxFlex fxLayout="column">
    <app-progress-bar [loading]="loading()" />

    <div fxLayout="row" fxLayoutAlign="end" class="abs-margin-top">
      <mat-form-field appearance="fill" (click)="start.open()" class="abs-clickable abs-margin-right">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="start" class="abs-clickable" [formControl]="startDateControl" />
        <mat-datepicker-toggle matSuffix [for]="start" />
        <mat-datepicker #start startView="multi-year" (monthSelected)="chosenMonthHandler($event, start, 'start')" />
      </mat-form-field>
      <mat-form-field appearance="fill" (click)="end.open()" class="abs-clickable">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="end" class="abs-clickable" [formControl]="endDateControl" />
        <mat-datepicker-toggle matSuffix [for]="end" />
        <mat-datepicker #end startView="multi-year" (monthSelected)="chosenMonthHandler($event, end, 'end')" />
      </mat-form-field>
    </div>

    @if (dataSource()?.entities?.length === 0) {
      <div class="abs-padding abs-align-center">No balance found</div>
    } @else {
      <div class="abs-scroll-auto abs-margin-bottom" fxFlex fxLayout="column" appScrollEnding (scrollEnding)="onTableScroll()">
        <table mat-table [dataSource]="dataSource()!">
          <ng-container matColumnDef="reportingMonthDate">
            <th mat-header-cell *matHeaderCellDef>Month</th>
            <td mat-cell *matCellDef="let element">{{ element.reportingMonthDate | date: 'MM/yyyy' }}</td>
          </ng-container>

          <ng-container matColumnDef="endBalance">
            <th mat-header-cell *matHeaderCellDef class="align-end"><span style="margin-right: 28px">End Balance</span></th>
            <td mat-cell *matCellDef="let element; let i = index" class="align-end">
              <div fxLayoutAlign="end center">
                <span>{{ element.endBalance | currency }}</span>
                <div style="width: 28px">
                  @if (i === 0 && element.manual) {
                    <button *hasPermission="'CREATE_TRANSACTION'" class="edit-balance" mat-icon-button (click)="editBalance()">
                      <mat-icon fontIcon="mdi-pencil" />
                    </button>
                  }
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="nav">
            <th mat-header-cell *matHeaderCellDef class="align-end">NAV</th>
            <td mat-cell *matCellDef="let element" class="align-end">{{ element.nav | currency }}</td>
          </ng-container>

          <ng-container matColumnDef="shares">
            <th mat-header-cell *matHeaderCellDef class="align-end">Shares</th>
            <td mat-cell *matCellDef="let element" class="align-end">{{ element.shares | number: '0.2-2' }}</td>
          </ng-container>

          <ng-container matColumnDef="transactionsValue">
            <th mat-header-cell *matHeaderCellDef class="align-end">Transactions</th>
            <td mat-cell *matCellDef="let element" class="align-end">{{ element.transactionsValue | currency }}</td>
          </ng-container>

          <ng-container matColumnDef="monthlyReturn">
            <th mat-header-cell *matHeaderCellDef class="align-end">MTD</th>
            <td mat-cell *matCellDef="let element" class="align-end">{{ element.monthlyReturn | number: '0.2-2' }}</td>
          </ng-container>

          <ng-container matColumnDef="qtd">
            <th mat-header-cell *matHeaderCellDef class="align-end">QTD</th>
            <td mat-cell *matCellDef="let element" class="align-end">{{ element.qtd | number: '0.2-2' }}</td>
          </ng-container>

          <ng-container matColumnDef="ytd">
            <th mat-header-cell *matHeaderCellDef class="align-end">YTD</th>
            <td mat-cell *matCellDef="let element" class="align-end">{{ element.ytd | number: '0.2-2' }}</td>
          </ng-container>

          <ng-container matColumnDef="itd">
            <th mat-header-cell *matHeaderCellDef class="align-end">ITD</th>
            <td mat-cell *matCellDef="let element" class="align-end">{{ element.itd | number: '0.2-2' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    }
  </div>
}
