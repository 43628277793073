<app-dialog-title displayTitle="Additional Info" [loading]="saving() || loading()" />

<mat-dialog-content>
  @if (!!formGroup) {
    <form (submit)="save()" id="info-form" [formGroup]="formGroup">
      <div class="tw-flex tw-flex-col">
        <div fxFlex fxLayout="column" class="abs-dashed-box abs-margin-bottom">
          <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field fxFlex>
              <mat-label>Category</mat-label>
              <mat-select formControlName="idtCategory">
                @for (category of categories$ | async; track category) {
                  <mat-option [value]="category.idtClientCategory">
                    {{ category.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-label>Subcategory</mat-label>
              <mat-select formControlName="idtSubcategory">
                @for (subcategory of subcategories$ | async; track subcategory) {
                  <mat-option [value]="subcategory.idtClientCategory">
                    {{ subcategory.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          @if (type === 'PERSON') {
            <div fxLayout="row" fxLayoutGap="8px">
              <mat-form-field>
                <mat-label>Shirt size</mat-label>
                <input matInput formControlName="shirtSize" maxlength="32" />
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>Spouse name</mat-label>
                <input matInput formControlName="spouseName" maxlength="100" />
              </mat-form-field>
            </div>

            @for (tag of tags; track tag.idtTag) {
              <app-tag-autocomplete [idtTag]="tag.idtTag" [label]="tag.name" [control]="getFormControlForTag(tag.idtTag)" />
            }
          }

          <mat-form-field>
            <mat-label>Contact key</mat-label>
            <input matInput formControlName="contactKey" maxlength="280" />
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="contactNotes" cdkTextareaAutosize cdkAutosizeMinRows="5" maxlength="3000"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  }
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="info-form">Save</button>
</div>
