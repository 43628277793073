import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { finalize, tap } from 'rxjs/operators';
import { PortfolioAllocation } from '../../../portfolio/model/portfolio-allocation.model';
import { OpportunityViewTO } from '../../model/opportunity.model';
import { OpportunityService } from '../../services/opportunity.service';

/**
 * Component for the ooportunity list.
 */
@Component({
  selector: 'app-home-opportunity-list',
  templateUrl: './home-opportunity-list.component.html',
  styleUrls: ['./home-opportunity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeOpportunityListComponent {
  /**
   * The list opportunities.
   */
  opportunities: OpportunityViewTO[] = [];

  /**
   * The selected opportunity.
   */
  @Input()
  selectedOpportunity?: OpportunityViewTO;

  /**
   * The selected portfolio to filter for.
   */
  private _portfolio?: PortfolioAllocation;

  get portfolio(): PortfolioAllocation | undefined {
    return this._portfolio;
  }

  @Input()
  set portfolio(value: PortfolioAllocation | undefined) {
    this._portfolio = value;
    this.getOpportunities();
  }

  loading = signal(false);

  /**
   * Emit event when an opportunity is selected.
   */
  @Output()
  selectOpportunity = new EventEmitter<OpportunityViewTO>();

  /**
   * Whehter to show selection filter chips.
   */
  @Input()
  showChip = false;

  /**
   * Event emitted if the portfolio chip is removed.
   */
  @Output()
  portfolioRemoved = new EventEmitter<void>();

  /**
   * Sort column and direction.
   */
  sort: MatSort;

  /**
   * Get the sort string.
   */
  get sortString(): string {
    return `${this.sort.active},${this.sort.direction}`;
  }

  constructor(
    private opportunityService: OpportunityService,
    private trackerService: TrackerService,
  ) {
    this.sort = new MatSort();
    this.sort.active = 'statusOrder';
    this.sort.direction = 'asc';
  }

  /**
   * Get the opportunities list.
   */
  private getOpportunities(): void {
    this.loading.set(true);

    this.opportunityService
      .getHomeOpportunities(this.portfolio?.idtPortfolio)
      .pipe(
        tap((opps) => {
          this.opportunities = opps;
          this.sortOpportunities();
        }),
        finalize(() => {
          this.loading.set(false);
        }),
      )
      .subscribe();
  }

  /**
   * Select an oppotunity.
   *
   * @param opportunity the selected opportunity
   */
  select(opportunity?: OpportunityViewTO): void {
    if (opportunity?.idtOpportunity === this.selectedOpportunity?.idtOpportunity) {
      this.selectOpportunity.emit(undefined);
    } else {
      this.selectOpportunity.emit(opportunity);
    }
  }

  /**
   * Remove a portfolio selecting.
   */
  removePortfolio(): void {
    this.portfolioRemoved.emit();
  }

  /**
   * Sort opportunities list by the selected property and direction.
   */
  sortOpportunities(): void {
    this.opportunities = [
      ...this.opportunities.sort((a: any, b: any) => (a[this.sort.active] < b[this.sort.active] ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1)),
    ];
  }

  /**
   * Changes the sorting of the list.
   *
   * @param property the property and direction to sort by
   */
  orderBy(property: string): void {
    if (this.sort.active === property) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.active = property;
      this.sort.direction = property === 'statusOrder' ? 'asc' : 'desc';
    }

    this.sortOpportunities();

    this.trackerService.event('opportunity_list', 'sort', { property, direction: this.sort.direction });
  }
}
