import { ChangeDetectionStrategy, Component, Input, WritableSignal, effect, signal } from '@angular/core';
import { DateTime } from 'luxon';
import { AccountBalanceTO } from '../../../client/model/client-balance.model';
import { AccountIndexXtdTO } from '../../../index/models/index.model';
import { IndexService } from '../../../index/services/index.service';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { AccountBalanceGroupBy } from '../contact-account-balance-list/contact-account-balance-list.component';

/**
 * Component to show an account list item with xTD data.
 */
@Component({
  selector: 'app-account-performance-list-item',
  templateUrl: './account-performance-list-item.component.html',
  styleUrls: ['./account-performance-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPerformanceListItemComponent {
  /**
   * The account data.
   */
  @Input({ required: true })
  account!: AccountBalanceTO;

  /**
   * Reference date.
   */
  @Input({ required: true })
  refDate!: DateTime;

  /**
   * What grouping was used in the list.
   */
  @Input()
  groupBy?: AccountBalanceGroupBy;

  /**
   * Whether the contact is a consultant.
   */
  @Input()
  isConsultant = false;

  /**
   * Whether to show the index data.
   */
  showIndex = signal(false);

  // When the selected tab is changed, also change the query params, so we can refresh or share the url and open at the same tab.
  private showIndexEffect = effect(() => {
    if (this.showIndex()) {
      this.trackerService.event('connected_accounts', 'expand_index', { account: this.account.accountName, contact: this.account.contactName });

      if (!this.indexData()) {
        this.loadIndexData();
      }
    }
  });

  /**
   * The index data.
   */
  indexData: WritableSignal<AccountIndexXtdTO | null> = signal(null);

  constructor(
    private indexService: IndexService,
    private trackerService: TrackerService,
  ) {}

  /**
   * Load index data from the server.
   */
  loadIndexData(): void {
    this.indexService.getAccountIndexXtd(this.account.idtAccount, DateTime.fromISO(this.account.referenceDate)).subscribe((data) => {
      this.indexData.set(data);
    });
  }

  toggleShowIndex(): void {
    this.showIndex.update((i) => !i);
  }
}
