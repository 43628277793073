@if (!loading() && isEstimated()) {
  <app-alert-box [showIcon]="false">Contains estimated values, denoted with an *</app-alert-box>
}

<app-custom-list class="abs-scroll-auto-y tw-flex tw-flex-1 tw-flex-col" [ngClass]="{ 'abs-scroll-hidden': loading() }">
  <!-- Skeleton items while loading -->
  <app-list-skeleton-loader [lines]="2" [height]="44" [items]="20" [loading]="loading()" />

  @if (!loading()) {
    <!-- Totalization line -->
    @if (total) {
      <app-custom-list-item [selected]="!selectedPortfolio" (click)="select()">
        <div class="tw-flex tw-flex-1">
          <div class="tw-flex tw-flex-1 tw-flex-col">
            <span class="abs-bold">TOTAL</span>
            <span class="abs-color-faded">{{ total.firmClients }} clients</span>
          </div>
          <div class="tw-flex tw-flex-1 tw-items-center tw-justify-end">
            @if (total.firmMissingReturns) {
              <mat-icon
                fontIcon="mdi-alert-outline"
                class="abs-color-warn tw-mr-0.5"
                matTooltip="Not full value due to missing returns in ABSolute"
              />
            }
            <div class="tw-flex tw-flex-col tw-items-end">
              <span>{{ total.totalFirm | currency }}{{ isEstimated() ? '*' : '' }}</span>
              <span class="abs-color-faded">{{ 1 | percent: '.2' }}</span>
            </div>
          </div>
        </div>
        <mat-divider />
      </app-custom-list-item>
    }

    <!-- Portfolios -->
    @for (portfolio of portfolios(); track portfolio.idtPortfolio) {
      <app-custom-list-item [selected]="selectedPortfolio?.idtPortfolio === portfolio.idtPortfolio" (click)="select(portfolio)">
        <div class="tw-flex tw-flex-1">
          <div class="tw-flex tw-flex-1 tw-flex-col">
            <app-portfolio-context-menu class="abs-bold" [id]="portfolio.idtPortfolio" [displayText]="portfolio.ticker" />
            <span class="abs-color-faded" [ngPlural]="portfolio.portfolioClients">
              <ng-template ngPluralCase="=1">1 client</ng-template>
              <ng-template ngPluralCase="other">{{ portfolio.portfolioClients }} clients</ng-template>
            </span>
          </div>
          <div class="tw-flex tw-flex-1 tw-flex-col tw-items-end tw-justify-center">
            @if (portfolio.missingReturns) {
              <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" matTooltip="Missing returns in ABSolute" />
            } @else {
              <span> {{ portfolio.totalPortfolio | currency }}{{ portfolio.estimated ? '*' : '' }} </span>
              <span class="abs-color-faded">{{ portfolio.portfolioAllocation / 100 | percent: '.2' }}</span>
            }
          </div>
        </div>
        <mat-divider />
      </app-custom-list-item>
    }
  }
</app-custom-list>
